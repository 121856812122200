import { graphql } from "gatsby";
import { GatsbyImage, getImage, getSrc, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { useTranslation } from "react-i18next";
import { RenderedHTMLAst } from "../components/layout/RenderedHTMLAst/RenderedHTMLAst";
import { TableOfContents } from "../components/layout/TableOfContents/TableOfContents";
import { Footer } from "../components/molecules/Footer/Footer";
import { NavigationHeader } from "../components/molecules/NavigationHeader/NavigationHeader";
import { RouterLink } from "../components/molecules/RouterLink/RouterLink";
import { SEO } from "../components/molecules/SEO/SEO";
import {
    Breadcrumb,
    ContentRoot,
    ContentsHeading,
    H1,
    Header,
    PageContainer,
    Root,
    Section,
    SpacerContainer,
    StickyContainer,
    Subtitle,
} from "../page-styles/markdown.styles";
import { GalahRoute } from "../services/Constants/GalahRoute";

const Template = ({ data }) => {
    const [t] = useTranslation();
    const { markdownRemark } = data;
    const { frontmatter, tableOfContents, timeToRead, htmlAst } = markdownRemark;
    const { title, date, description, featuredImage, author, tag, slug } = frontmatter;
    const image = getImage(featuredImage) as IGatsbyImageData;
    const featuredImageSrc = `https://${process.env.GATSBY_HOST}${getSrc(featuredImage)}`;
    const canonicalUrl = `https://${process.env.GATSBY_HOST}/${slug}`;
    console.log(htmlAst);
    return (
        <React.Fragment>
            <NavigationHeader />
            <SEO title={title} description={description} socialImage={featuredImageSrc} relativeCanonicalUrl={canonicalUrl} />
            <Root>
                <PageContainer>
                    <StickyContainer>
                        <ContentsHeading>{t("contents")}</ContentsHeading>
                        <TableOfContents dangerouslySetInnerHTML={{ __html: tableOfContents }} />
                    </StickyContainer>
                    <ContentRoot>
                        <Header>
                            <Breadcrumb>
                                <RouterLink href={GalahRoute.Home}>{t("blogTemplatePage.blogs")}</RouterLink> / {title}
                            </Breadcrumb>
                            <H1>{title}</H1>
                            <Subtitle>
                                {date} • {author} • {tag} • {t("blogTemplatePage.readingTime", { minutes: timeToRead })}
                            </Subtitle>
                        </Header>

                        <Section>
                            <GatsbyImage image={image} alt={title} style={{ marginTop: 16, marginBottom: 16, borderRadius: 8 }} />
                        </Section>

                        <RenderedHTMLAst ast={htmlAst} />
                        {/* <Section className="markdown-body" dangerouslySetInnerHTML={{ __html: html }} /> */}
                    </ContentRoot>
                    <SpacerContainer></SpacerContainer>
                </PageContainer>
            </Root>
            <Footer />
        </React.Fragment>
    );
};
export default Template;

export const pageQuery = graphql`
    query ($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            tableOfContents(absolute: true, pathToSlugField: "frontmatter.slug")
            timeToRead
            htmlAst
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                slug
                title
                description
                author
                tag
                featuredImage {
                    childImageSharp {
                        gatsbyImageData(width: 800, height: 400, placeholder: BLURRED, transformOptions: { fit: COVER })
                    }
                }
            }
        }
    }
`;
